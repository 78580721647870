import React, { useEffect, useState } from 'react';
import Brands from '../components/Brands';
import Projects from '../components/Projects';
import Testimonial from '../components/Testimonial';
import Loader from '../components/Loader'; 
import Hero from '../components/Hero';
import About from '../components/About';
import Service from '../components/Service';
import Experience from '../components/Experience';
import Contact from '../components/Contact';

export default function Home() {
  const [homeData, setHomeData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('https://rushabhsorathiya.com/API/HomePagdData.json')
      .then(response => response.json())
      .then(data => {
        setHomeData(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loader />; // Display loader while data is being fetched
  }

  if (!homeData) {
    return <div>Error: Failed to fetch data</div>; // Display error message if data fetching fails
  }

  const {
    hero,
    socialBtns,
    brands,
    about,
    projects,
    service,
    experience,
    contact,
  } = homeData;

  return (
    <>
      <Hero data={hero} socialData={socialBtns} />
      <About data={about} />
      <Projects data={projects} />
      <Service data={service} />
      <Experience data={experience} />
      <Contact data={contact} socialData={socialBtns} />
    </>
  );
}
